

import {defineComponent} from "vue";
import {useApplicationStore} from "@/store/application";
import {mapState} from "pinia";
//import ContactForm from "@/views/partials/ContactForm.vue";

export default defineComponent({
  setup(){
    const applicationStore = useApplicationStore()
    return {
      applicationStore,
    }
  },
  components:{
    //ContactForm
  },
  created(){
    if(this.applicationStore.secured){
      //this.$router.push("/services")
    }
  },
  data(){
    return{
      securing: false,
      signInEmailErrors: [],
      signInPasswordErrors: [],
      signin:{
        email:null,
        password:null,
      },
    }
  },
  computed:{
   ...mapState(useApplicationStore, {
     secured:'secured'
   })
  },
  methods:{
    onIG(){
      window.open("https://www.instagram.com/littletujungashearing/", "_blank")
    },
    onEmail(){
      window.open("mailto:hello@littletujungashearing.com", "_blank")
    }
  }
})
