
import {useApplicationStore} from "@/store/application";
import {mapState, mapWritableState} from "pinia";
import {defineComponent} from "vue";

export default defineComponent({
  setup(){
    const applicationStore = useApplicationStore();
    return{
      applicationStore
    }
  },
  computed:{
    ...mapWritableState(useApplicationStore, {
      rail: 'rail'
    }),
    ...mapState(useApplicationStore, {
      secured(store) {
        return store.secured
      }
    }),
  },
  data(){
    return{

      menuItems: [
        //{ text: 'Home', icon: 'mdi-clock', path:'/home' },
        { text: 'About', icon: 'mdi-account', path:'/about' },
        { text: 'Shearing', icon: 'mdi-flag', path:'/shearing' },
        //{ text: 'Rates', icon: 'mdi-flag', path:'/rates' },
        { text: 'Contact', icon: 'mdi-account', path:'/contact' },
      ],
      profileItems:[
        { text: 'account', icon: 'mdi-account', path:'/account' },
        { text: 'logout', icon: 'mdi-logout', path:'/logout' },
      ]
    }
  },
  methods:{
    goHome(){
      console.log('goHome')
      this.$router.push('/home')
    },
    onProfileMenuClick(item:any){
      if(item.path.indexOf('logout') > -1){
        this.logout()
      }else{
        this.$router.push(item.path)
      }
    },
    logout(){
      this.applicationStore.signOut()
    }
  }
})
