export const required = (value: any) => {return !!value || 'Required'}
export const baseRules:any = [
    required
]
export const validateEmail:any = (value:any) => {
    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return pattern.test(value) || 'Invalid e-mail.'
}

export const emailRules:any = baseRules.concat(validateEmail)
