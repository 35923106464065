<template>
  <v-layout>

    <v-img
        class="bg-white"
        src="https://images.unsplash.com/photo-1604087472033-cae0bd418fec?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2500&q=80"
        cover
    >
    <AppBarMenu></AppBarMenu>
    <v-main>
      <div class="mx-15">
        <v-alert class="mx-0 mx-sm-15 mx-md-10" v-if="errors && errors.length" closable variant="outlined" type="error">
          <div v-for="(err,index) in errors" :key="index">{{ err }}</div>
        </v-alert>
      </div>
      <router-view></router-view>
<!--      <div class="text-white">
        <v-icon
            @click="onIG"
            size="large"
            icon="mdi-instagram"
        ></v-icon>
      </div>-->
      <v-tooltip
          location="top"
      >
        <template v-slot:activator="{ props }">
          <v-btn
              :ripple="false"
              variant="plain"
              icon
              v-bind="props"
              @click="onIG"
          >
            <v-icon color="white">
              mdi-instagram
            </v-icon>
          </v-btn>
        </template>
        <span>Instagram</span>
      </v-tooltip>
      <div class="mb-5">
        <span class="xrs_text_neutral font-weight-bold">{{ new Date().getFullYear() }} - </span>&nbsp;<a class="text-white" target="_self" href="/home" @click.stop> LITTLE TUJUNGA SHEARING</a>
      </div>

    </v-main>
    </v-img>
  </v-layout>
</template>

<script>
import AppBarMenu from "@/views/partials/AppBarMenu";
import {mapState} from "pinia";
import {useApplicationStore} from "@/store/application";
export default {
  name: "DashBoard",
  components: {AppBarMenu},
  data:()=>{
    return {
      showNavMenu : true
    }

  },
  methods:{
    onIG(){
      window.open("https://www.instagram.com/littletujungashearing/", "_blank")
    },
  },
  computed:{
    ...mapState(useApplicationStore, {
      errors:'errors',
    })
  }
}
</script>

<style>
 .logo{
   height: 60px;
 }

 .v-row + .v-row {
   margin-top: 0px !important;
 }

 .topbar-menu{
   position: absolute;
 }

 @media only screen and (max-width: 600px) {
   h1 {
     font-size: 1.5em;
   }
   .topbar-menu{
     position: relative;
   }
 }

 @media only screen and (min-width: 600px) and (max-width: 960px){

   .topbar-menu{
     position: relative;
   }
 }

/* @media only screen and (max-width: 600px) {
   .logo {
     width: 50px;
   }
 }

 @media only screen and (min-width: 600px) and (max-width: 960px) {
   .logo {
     width: 50px;
   }
 }

 @media only screen  and (min-width: 960px) and (max-width: 1264px) {
   .logo {
     width: 50px;
   }
 }

 @media only screen  and (min-width: 1264px) and (max-width: 1904px) {
   .logo {
     width: 95px;
   }
 }
 @media only screen and (min-width: 1904px) {
   .logo {
     width: 50px;
   }
 }*/
</style>
