import {defineStore} from "pinia";
import {submitContact} from "@/store/services/api";


export const useApplicationStore = defineStore({
    id: "applicationStore",
    state: () => {
        return {
            rail: false,
            secured: false,
            returnUrl: null,
            user:{
                email: null,
            },
            errors:[],
        } as any
    },
    getters: {},
    actions: {
        clearErrors(){
            this.errors = []
        },
        async contact(contact:any){
            console.log()
            try {
                this.clearErrors()
                const data = await submitContact(contact)
                console.log( data )
                //this.secured = true
                //localStorage.setItem('xirsysSecured', 'true')
                //await router.push(this.returnUrl || '/services')
            } catch (e:any) {
                //
                this.errors.push(e.message)
                console.log(e.message)
            }
        },
    }
})
