import { createWebHistory, createRouter } from "vue-router";
import NotFound from "@/views/components/NotFound.vue";
import MainBoard from "@/views/layouts/MainBoard.vue";
import {useApplicationStore} from "@/store/application";
import AboutComp from "@/views/components/AboutComp.vue";
import HomeComp from "@/views/components/HomeComp.vue";
import ShearingComp from "@/views/components/ShearingComp.vue";
import ContactComp from "@/views/components/ContactComp.vue";
import RatesComp from "@/views/components/RatesComp.vue";

const routes = [
    {
        path: "/",
        name: "Main",
        component: MainBoard,
        redirect:"/home",
        children: [
            {
                path: '/',
                name: 'Home',

                component: HomeComp,
            },
            {
                path: '/home',
                redirect: '/'
            },
            {
                path: 'about',
                name: 'About',

                component: AboutComp,
            },
            {
                path: 'shearing',
                name: 'Shearing',

                component: ShearingComp,
            },
            {
                path: 'rates',
                name: 'Rates',

                component: RatesComp,
            },
            {
                path: 'contact',
                name: 'Contact',

                component: ContactComp,
            },
            {
                path: "/",
                component: NotFound,

            }
        ]
    },
    {
        path: "/:catchAll(.*)",
        component: NotFound,
    },
];

export const router = createRouter({
    history: createWebHistory(),
    routes,
});
router.beforeEach(()=>{
    const applicationStore = useApplicationStore();
    applicationStore.secured = true
})

/*
router.beforeEach(async (to) => {
    // clear alert on route change
    //const alertStore = useAlertStore();
    //alertStore.clear();

    // redirect to login page if not logged in and trying to access a restricted page
    const publicPages = ['/signin', '/signup'];
    const authRequired = !publicPages.includes(to.path);
    const applicationStore = useApplicationStore();
    //const accountStore = useAccountStore()
    const lStorage = localStorage.getItem('xirsysSecured')
    document.title = to.name as string
    if (authRequired) {
        if(!applicationStore.secured && !lStorage){
            applicationStore.returnUrl = to.fullPath;
            return '/signin';
        }else{
            //check dates
            if (applicationStore.secured && !lStorage){
                localStorage.setItem('lts', 'true')
            }else {
                applicationStore.secured =  !!lStorage
            }
        }
    }else{
        //
    }
});
*/

export default {
    router
};
