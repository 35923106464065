import needle from "needle";

function parseApiResponse(type:string, response:any){
    console.log('parseApiResponse', type, response)
    const {data, error} = response.body
    if(!error){
        return data
    }else{
        throw Error(`${type.toUpperCase()} Error: ${error}`)
    }
}
export const submitContact = async (contact:any)=>{
    console.log('submitContact')
    try {
        const res:any =  await needle('post','https://littletujungashearing.com/api/contact', contact, {json:true})
        return parseApiResponse('contact', res)
    } catch (e) {
        console.log('submitContact err - ', e)
        throw e;
    }
}
