

import {defineComponent} from "vue";
import {baseRules, emailRules} from "@/store/services/utlis";
import {useApplicationStore} from "@/store/application";
import {mapState} from "pinia";

export default defineComponent({
  setup(){
    const applicationStore = useApplicationStore()
    return {
      applicationStore,
      baseRules,
      emailRules
    }
  },
  created(){
    if(this.applicationStore.secured){
      //this.$router.push("/services")
    }

  },
  data(){
    return{
      securing: false,
      signInEmailErrors: [],
      signInPasswordErrors: [],
      signin:{
        email:null,
        password:null,
      },
    }
  },
  computed:{
   ...mapState(useApplicationStore, {
     secured:'secured'
   })
  },
  methods:{
    async login() {
      this.securing = true
      if (!this.signin.email) {
        this.signInEmailErrors = ['Required.'] as any
        this.securing = false
        return
      }
      if (!this.signin.password) {
        this.signInPasswordErrors = ['Required.'] as any
        this.securing = false
        return
      }
      await this.applicationStore.signIn(this.signin.email, this.signin.password)
      this.securing = false
    }

  }
})
